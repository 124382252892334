import React from 'react'

export default function Pagination({gotoNextPage, gotoPrevPage}) {
  return (
    <div>
      {gotoPrevPage &&<button id="kol" onClick={gotoPrevPage} type="button" >Previous</button>}
      {gotoNextPage &&<button id="kol" onClick={gotoNextPage} type="button" >Next</button>}


    </div>
  )
}
