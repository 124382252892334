import React, {useState,useEffect} from 'react';
import './App.css';
import PokemonList from './PokemonList';
import axios from 'axios';
import Pagination from './Pagination';
import $ from 'jquery'
import Obrazek from './Obrazek';




function App() {

  const [pokemon, setPokemon]= useState([])
  const [currentPageUrl, setcurrentPageUrl]= useState("https://pokeapi.co/api/v2/pokemon")
  const [nextPageUrl, setNextPageUrl]= useState()
  const [prevPageUrl, setPrevPageUrl]= useState()
  const [loading, setLoading] = useState(true)
  const [currentColor, setColor]= useState(true)

  useEffect(()=>{
    setLoading(true)
   let cancel
    axios.get(currentPageUrl,{
      cancelToken: new axios.CancelToken(c=>cancel=c)
    }).then(res => { 
      
     
      setLoading(false)
      setNextPageUrl(res.data.next)
      setPrevPageUrl(res.data.previous)
      setPokemon(res.data.results.map(p=>p.name))
    });

   return ()=>cancel()

  },[currentPageUrl]);


      function    gotoNextPage(){

        setcurrentPageUrl(nextPageUrl)
              setnextColor();
      }

      function    gotoPrevPage(){

        setcurrentPageUrl(prevPageUrl)
             setnextColor();
      }

      function setnextColor(){
         setColor(!currentColor);
       
      }







  if (loading) return  "Loading.......";
 
  return (
    
      
       <div className='row'>
        <span>
        API : https://pokeapi.co/api/v2/pokemon
        </span>
        <div className='col' id='sloup'>
          <Obrazek currentColor={currentColor}/>
        </div>
        <div className='col' id='rada'>
    
          <PokemonList  pokemon = {pokemon}/>
            <Pagination 
            gotoNextPage={nextPageUrl ? gotoNextPage:null}
            gotoPrevPage={prevPageUrl ? gotoPrevPage: null}
          />
        
        </div>
     
        </div>

  
  
  );

  }
export default App;
