import React from 'react'
import './App.css';


export default function Obrazek({currentColor}) {

const classJmeno=currentColor?"row greenRow":"row";
const classSloupec=currentColor?"col orangeCol":"col";
  return (
    <>
    <div div className='row' >
      <div className={classSloupec}>
        <img src="../../images/pok.png" width="400" height="450" alt="pokemon" />
        <div className ={classJmeno}>
          <div className="col"> 
            <span>this sipmple app displays different names of pokemons through fetching data from API</span>
          </div>
        
        </div>
      </div>
 </div>



 </>
  );
}
